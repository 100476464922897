<script>
import CartButton from '../components/CartButton.vue';
import axios from 'axios';

export default {
  name: 'ItemComponent',
  components: {
    CartButton
  },
  props: {
    // items list
    master_item_ids: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    let addOnItems = [];
    return {
      addOnItems
    };
  },
  mounted() {
    this.fetchAddOnItems();
  },
  methods: {
    trimString(str) {
      let MAX_LENGTH = 75;
      if (str.length <= MAX_LENGTH) {
        return str;
      }

      let trimmedString = str.slice(0, MAX_LENGTH);
      let lastSpace = trimmedString.lastIndexOf(' ');
      let lastPeriod = trimmedString.lastIndexOf('.');

      // Find the maximum index of the last space or period
      let breakPoint = Math.max(lastSpace, lastPeriod);

      // If no space or period is found, use the full 50 characters
      if (breakPoint === -1) {
        breakPoint = MAX_LENGTH;
      }

      return str.slice(0, breakPoint) + '...';
    },
    fetchAddOnItems() {
      console.log('fetchAddOnItems', this.master_item_ids);
      if (this.master_item_ids && this.master_item_ids.length > 0) {
        axios
          .post(this.apiUrl + 'add-ons', {
            master_item_ids: this.master_item_ids
          })
          .then((response) => {
            // comes back as an array of items or 1 item
            this.addOnItems = response.data.items;
            if (response.data.item) {
              this.addOnItems.push(response.data.item);
            }
          })
          .catch((error) => {
            console.log('add-ons', error.message);
          });
      }
    }
  }
};
</script>

<template>
  <!--- Suggested Add Ons -->
  <div v-if="addOnItems && addOnItems.length > 0">
    <div class="item-container my-3">
      <h4>Suggested Add Ons</h4>
      <ul class="flex-ul">
        <li v-for="addonItem in addOnItems" :key="addonItem.id">
          <img :src="addonItem.thumb_image_url" class="addon-image" />
          <div class="link addon-title">{{ trimString(addonItem.title) }}</div>
          <span class="price">
            {{ $money(addonItem.sell_price) }}
          </span>
          <CartButton :item="addonItem"></CartButton>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.addon-image {
  width: 100px;
}
.addon-title {
}
.flex-ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style-type: none;
  padding: 0;
  font-size: 13px;
}
.flex-ul li {
  flex: 1 1 22%; /* Adjust percentage as needed */
  box-sizing: border-box;
  margin: 0.5%;
}
.flex-ul li:not(:last-child) {
  border-right: 1px solid #ccc;
}
@media (max-width: 776px) {
  .flex-ul li {
    flex: 1 1 100%;
    margin: 0;
    padding: 20px 0;
  }
  .flex-ul li:not(:last-child) {
    border-right: none;
    border-bottom: 1px solid #ccc;
  }
}
</style>
