<script>
export default {
  data() {},
  mounted() {}
};
</script>

<template>
  <b
    >Stock orders $75.00 or more qualify for free delivery to COMMERCIAL addresses within
    Atlas Free Delivery Zones. Delivery addresses that are residential or non-commercial,
    or those that are outside Atlas Free Delivery Zones default to Will Call status at
    your nearest Atlas warehouse for pick up by you. Heavy equipment orders may be subject
    to delivery surcharges. Call us as 800-530-4010 or email us as
    sales@atlasrestaurantsupply.com for assistance.
  </b>

  <h3>Shipping Policy</h3>

  Shipping estimates to US customers will usually be a line item in the order. Shipments
  under 100 pounds will be delivered via UPS Ground. Shipments 100 lbs and over, or
  shipments with a length over 90 inches, will be delivered via common carrier. We
  recommend that you utilize the liftgate service for items 100 pounds and over. Liftgate
  Service lowers the shipment from the delivery vehicle to curbside and does not include
  inside delivery, un-crating or installation. The fee for Liftgate Service varies between
  Residential and Commercial delivery addresses, which are managed by you in your Customer
  Profile or during checkout using our Shipping Calculator. We are unable to deliver to
  Post Office Boxes or to APO/FPO addresses.

  <br /><br />
  If our shipping carrier determines that your address is inaccessible for delivery,
  please contact atlasrestaurantsupply.com Customer Service at 800-530-4010 (9:00 a.m. -
  5:00pm EST, M-F) immediately so that we may assist you in making other arrangements.
  This may involve final delivery at the nearest shipping terminal. In whatever manner you
  receive a delivery, please note that it remains your responsibility to follow our Rules
  of Receiving guidelines.

  <br /><br />
  Most orders arrive within 5 - 14 days from completion of your order. Special Orders,
  orders for furniture or large equipment may take longer. You can track the progress of
  your delivery by clicking on "Order Status" after you have placed your order. All
  displayed transit times are estimates only and do not include day of pickup. Pickup
  dates are not guaranteed. If delivery of your shipment is date sensitive, or if your
  shipping address requires special consideration (such as being rural or difficult to
  access), please note all deliveries are subject to schedules determined by the shipper
  and allow for ample delivery time. We are not responsible for unforeseen delays by the
  manufacturer or the freight carrier.

  <br /><br />
  Occasionally, there may be an error in automated estimation of shipping charges, and
  atlasrestaurantsupply.com reserves the right to increase shipping costs on orders.
  atlasrestaurantsupply.com will contact Customer if there is a change in shipping cost,
  and Customer approval must be received before an item with a shipping price change
  ships.

  <br /><br />
  <h3>Warranties</h3>
  Unless specifically identified 'FOR RESIDENTIAL USE,' all equipment is intended for
  commercial use only. Most warranties, if any, are void if equipment is installed or
  utilized in other than a commercial application. Additionally, if install venue is
  remote, or to an island or other venue not readily accessible, additional travel time
  may not be covered. Warranties valid only in the 50 United States. Refer to the warranty
  provided by the manufacturer for specifics.

  <br /><br />
  <h3>Taxes</h3>
  AtlasRestaurantSupply.com is not responsible for taxes, if any, specific to delivery
  jurisdiction.

  <br /><br />
  <h3>Rules of Receiving</h3>
  Care is taken when packing your order and all equipment is inspected before shipping,
  but on occasion a shipment may be lost or damaged in transit. At the time your shipment
  is received, it is your responsibility to follow these guidelines. Before you sign the
  delivery receipt (or bill of lading), and before the driver departs:
  <br />
  - Verify the number of cartons.
  <br />
  - Open, unpack and thoroughly inspect your shipment for any damage.
  <br />
  - If damage or shortages are discovered, you must make a notation on all copies of the
  freight bill describing the damage or shortage in detail.
  <br />
  - It is your right to refuse any shipment that shows signs of damage or shortage at the
  time of delivery.
  <br />
  - Your signature on the delivery receipt is an acknowledgement that the shipment was
  received in good condition and without damage or shortage.
  <br />
  - If the driver demands to leave before all portions of your shipment have been
  inspected, note on the delivery receipt (near your signature) that the driver would not
  allow for inspection.
  <br />
  - If you have questions during delivery, please call atlasrestaurantsupply.com Customer
  Service at 800-530-4010 (9:00 a.m. - 5:00pm EST, M-F). If concealed damage is
  discovered:
  <br />
  - Save all shipping cartons and packing materials.
  <br />
  - Immediately request an inspection by the carrier and file a freight claim.
  <br />
  If packing materials are not saved, your opportunity to recover damages will be
  restricted. Failure to follow any of the above mentioned guidelines will also restrict
  your ability to return merchandise or file a claim for damages. The freight carrier is
  responsible for loss or damage. It is your responsibility to file all claims with the
  freight carrier in a timely fashion in accordance with their terms and conditions.

  <br /><br />
  <h3>Redelivery or Reconsignment</h3>
  If the freight carrier attempts delivery and is unable to deliver for reasons that are
  within your control, redelivery fees may apply. You are responsible for such additional
  fees, if any, and your credit card will be charged. The freight company will not deliver
  to an address different from the original order. Once a shipment is in transit, you may
  not change the delivery address (also known as reconsignment).

  <br /><br />
  <h3>Cancelled/Refused Orders</h3>
  If you decide to cancel or refuse any part of your order after it has been shipped,
  please note that you will be responsible for the full amount of roundtrip shipping and
  handling charges, plus any applicable restocking fees and any additional fees that may
  be incurred by atlasrestaurantsupply.com. (Except for damaged equipment refused at time
  of delivery only.)

  <br /><br />
  We reserve the right to select the shipping method on all quoted shipments. Please
  contact atlasrestaurantsupply.com Customer Service at 800-530-4010 (9:00 a.m. - 5:00pm
  EST, M-F) for special consideration in shipping requests.

  <br /><br />
  <h3>Return Policy</h3>
  Please contact our Customer Service department before returning any order to receive a
  Return Authorization Number. A Return Authorization Number is required before attempting
  to return any order. Requests for returns must be made within seven (7) days of receipt
  of your order. Certain items may be returned and a 25% - 50% restocking fee, plus
  freight and any other costs that may be incurred by atlasrestaurantsupply.com, will be
  applied. Items that require fabrication upon order are generally disallowed from return.
  We may not accept returns of certain non-stock items, including but not limited to:
  <ul>
    <li>
      Any item that has any signs of use. Determination of "use" are the sole discretion
      of www.atlasrestaurantsupply.com.
    </li>
    <li>
      Any item that is not in its original condition, is damaged, or is missing parts.
    </li>
    <li>Items returned without a Return Authorization Number.</li>
  </ul>

  Items must be properly packaged in its original packaging and all shipping must be
  pre-paid. Provided the items return in an undamaged and unused condition and the above
  terms met, your refund, less restocking fee, will be processed.

  <br /><br />

  Please call or email with any questions.
  <br /><br />

  AtlasRestaurantSupply.com<br />
  <a href="mailto:sales@atlasrestaurantsupply.com">sales@atlasrestaurantsupply.com</a
  ><br />
  phone:800-530-4010<br />
</template>
