<script>
export default {
  data() {},
  mounted() {}
};
</script>

<template>
  <h2>Shipping Policy</h2>
  Shipping estimates to US customers is a line item in the order. Shipments under 100
  pounds will be delivered via UPS Ground. Shipments 100 lbs and over, or shipments with a
  length over 90 inches, will be delivered via LTL common carrier. We recommend that you
  utilize the liftgate service for items 100 pounds and over. Liftgate Service lowers the
  shipment from the delivery vehicle to curbside and does not include inside delivery,
  un-crating or installation. The fee for Liftgate Service varies between Residential and
  Commercial delivery addresses, which are managed by you in your Customer Profile or
  during checkout using our Shipping Calculator. We are unable to deliver to Post Office
  Boxes or to APO/FPO addresses.
  <br /><br />If our shipping carrier determines that your address is inaccessible for
  delivery, please contact MyChefStore.com Customer Service at 866-446-2930 (10:00 a.m. -
  6:00pm PST, M-F) immediately so that we may assist you in making other arrangements.
  This may involve final delivery at the nearest shipping terminal. In whatever manner you
  receive a delivery, please note that it remains your responsibility to follow our Rules
  of Receiving guidelines. <br /><br />Most orders arrive within 5 - 14 days from
  completion of your order. Special Orders, orders for furniture or large equipment may
  take longer. SHIPPING TIMELINES ARE NOT GUARANTEED. All displayed shipping terms are
  estimates only and cannot account for delays by the freight carrier, including but not
  limited to inclement weather or traffic delays. If delivery of your shipment is date
  sensitive, or if your shipping address requires special consideration (such as being
  rural or difficult to access), please contact MyChefStore.com Customer Service at
  866-446-2930 (10:00 a.m. - 6:00pm PST, M-F) or email sales@mychefstore.com immediately
  so that we may assist you in making arrangements. <br /><br />Occasionally, there may be
  an error in automated estimation of shipping charges, and MyChefStore.com reserves the
  right to increase shipping costs on orders. MyChefStore.com will contact Customer if
  there is a change in shipping cost, and Customer approval must be received before an
  item with a shipping price change ships.

  <br /><br />

  <h2>Alaska and Hawaii</h2>
  In most instances, we are able to estimate shipping charges to both Alaska and Hawaii.
  <br /><br />Hawaii: For Oahu, estimates include curbside delivery. For Maui, Lanai,
  Hawaii (the Big Island), Molokai and Kauai, delivery includes FOB Young Brothers dock
  only <br /><br />Alaska: Certain remote addresses may be inaccessible for delivery. If
  you have difficulty obtaining a real time estimate during the ordering process, please
  contact MyChefStore.com Customer Service at 866-446-2930 (10:00 a.m. - 6:00pm PST, M-F)
  or email sales@mychefstore.com immediately so that we may assist you in making
  arrangements.

  <br /><br />

  <h2>WARRANTIES</h2>
  All equipment is intended for COMMERCIAL USE ONLY, and is disallowed for residential
  use. Warranties, if any, are provided by the manufacturer and not MyChefStore.
  Manufacturer warranties are void if equipment is installed or utilized in other than a
  commercial application. If install venue is remote, or to an island or other venue not
  readily accessible, additional travel time may not be covered. Warranties valid only in
  the 50 United States. Refer to the warranty provided by the manufacturer for specifics.
  <br /><br />

  <h2>Rules of Receiving</h2>
  Care is taken when packing your order and all equipment is inspected before shipping,
  but on occasion a shipment may be lost or damaged in transit. At the time your shipment
  is received, it is your responsibility to follow these guidelines.
  <br /><br />

  Before you sign the delivery receipt (or bill of lading), and before the driver departs:
  <ul>
    <li>Verify the number of cartons.</li>
    <li>
      Open, unpack and thoroughly inspect your shipment for any damage. If damage or
      shortages are discovered, you must make a notation on all copies of the freight bill
      describing the damage or shortage in detail.
    </li>
    <li>
      It is your right to refuse any shipment that shows signs of damage or shortage at
      the time of delivery.
    </li>
    <li>
      Your signature on the delivery receipt is an acknowledgement that the shipment was
      received in good condition and without damage or shortage.
    </li>
    <li>
      If the driver demands to leave before all portions of your shipment have been
      inspected, note on the delivery receipt (near your signature) that the driver would
      not allow for inspection.
    </li>
  </ul>

  If concealed damage is discovered:
  <ul>
    <li>Save all shipping cartons and packing materials.</li>
    <li>Immediately request an inspection by the carrier and file a freight claim.</li>
    <li>
      If packing materials are not saved, your opportunity to recover damages will be
      restricted. Failure to follow any of the above mentioned guidelines will also
      restrict your ability to return merchandise or file a claim for damages.
    </li>
    <li>
      The freight carrier is responsible for loss or damage. MyChefStore includes freight
      protection coverage in the cost we pay the carrier. It is your responsibility to
      file all claims with the freight carrier in a timely fashion in accordance with
      their terms and conditions.
    </li>
  </ul>

  <h2>Redelivery or Reconsignment</h2>
  If the freight carrier attempts delivery and is unable to deliver for reasons that are
  within your control, redelivery fees may apply. You are responsible for such additional
  fees, if any, and your credit card will be charged. The freight company will not deliver
  to an address different from the original order. Once a shipment is in transit, you may
  not change the delivery address (also known as reconsignment).

  <br /><br />

  <h2>Cancelled/Refused Orders</h2>
  If you decide to cancel or refuse any part of your order after it has been shipped, you
  will be responsible for the full amount of roundtrip shipping and handling charges, plus
  any applicable restocking fees and any additional fees that may be incurred by
  MyChefStore.com. (Except for damaged equipment refused at time of delivery only.)

  <br /><br />

  <h2>Return Policy</h2>
  Contact our Customer Service department to receive a Return Authorization Number. A
  Return Authorization Number is required PRIOR to return. Certain items may be returned
  but a 25% - 50% restocking fee, plus outbound and return freight and any other costs
  that may be incurred by MyChefStore.com. Items are returned to the manufacturer for
  certification of unused and undamaged status. We may not accept returns of certain
  items, including but not limited to:
  <ul>
    <li>
      Requests for returns must be made within seven (7) days of delivery of any order.
    </li>
    <li>
      Items that require fabrication upon order are generally disallowed from return.
    </li>
    <li>
      Any item that has any signs of use. Determination of "use" are the sole discretion
      of MyChefStore.com.
    </li>
    <li>
      Any item that is not in its original condition, is damaged, or is missing parts.
    </li>
    <li>Items returned without a Return Authorization Number.</li>
    <li>
      Items must be packaged in its original packaging and all shipping must be pre-paid.
    </li>
    <li>
      Provided the item is returned in an undamaged and unused condition and the above
      terms met, your refund, less freight and restocking fee, will be processed.
    </li>
  </ul>
  <br /><br />
</template>
