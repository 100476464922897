<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      required: true
    }
  },
  data() {
    console.log('Initial child data :', this.value);
    return {
      selectedOption: this.value
    };
  },
  watch: {
    value(newVal) {
      this.selectedOption = newVal;
    }
  },
  methods: {
    updateOption() {
      this.$emit('updated-option-event', this.selectedOption);
    }
  }
};
</script>

<template>
  <span class="option-label float-end">{{ label }}</span>
  <div class="clearfix"></div>
  <select
    v-model="selectedOption"
    class="form-select form-select-xs float-end"
    @change="updateOption"
  >
    <option v-for="option in options" :key="option" :value="option">
      {{ option }}
    </option>
  </select>
  <div class="clearfix"></div>
</template>

<style scoped>
.option-label {
  font-size: 0.8rem;
  font-weight: 500;
  color: #6c757d;
  margin-right: 10px;
}
.form-select-xs {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  background-color: #efefef;
}
</style>
