<script>
import NavBarAdmin from './components/layouts/NavBarAdmin';
import CartHeader from './components/layouts/CartHeader';
import ClientFooter from './components/layouts/ClientFooter';
import ClientNavBar from './components/layouts/ClientNavBar';
import ToastView from './components/ToastView';

export default {
  name: 'UserHome',
  components: {
    NavBarAdmin,
    CartHeader,
    ClientFooter,
    ClientNavBar,
    ToastView
  },
  data() {
    return {
      flash_timout: 5000
    };
  }
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<template>
  <template v-if="$route.meta.is_admin">
    <div class="admin-container">
      <ToastView />
      <NavBarAdmin />
      <router-view />
    </div>
  </template>

  <template v-else-if="$route.meta.no_header"> <router-view /></template>

  <template v-else>
    <div class="container pt-3 pb-3">
      <ToastView />
      <CartHeader />
      <ClientNavBar />
      <div class="center my-2 help">
        If you have any questions please call customer service at 808-845-5755
      </div>

      <router-view />
      <ClientFooter />
    </div>
  </template>
</template>

<style>
.help {
  color: #003767;
  color: #198754;
  font-style: italic;
}
</style>
