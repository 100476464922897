<script>
import { useAuthStore } from '@/stores/authStore';
import SearchBar from '@/components/SearchBar';

export default {
  name: 'CartHeader',
  components: {
    SearchBar
  },
  data() {
    const authStore = useAuthStore();
    return {
      authStore
    };
  },
  methods: {
    viewCart() {
      this.authStore.closeToastCart();
      this.$router.push({ path: '/shopping-cart' });
    }
  }
};
</script>

<template>
  <div class="d-flex bd-highlight">
    <div class="bd-highlight">
      <div v-if="clientName === 'atlas'">
        <a class="navbar-brand" href="/"
          ><img
            src="https://www.atlasrestaurantsupply.com/cobrands/atlas/default/images/logo.gif"
        /></a>
      </div>
      <div v-if="clientName === 'midcity'">
        <a class="navbar-brand" href="/"
          ><img src="https://ext-chef.s3.us-west-2.amazonaws.com/MidCityLogo.png"
        /></a>
      </div>
    </div>
    <div class="flex-grow-1 bd-highlight"></div>

    <SearchBar class="mx-3 d-none d-md-block"></SearchBar>
    <!--- profile -->
    <div v-if="authStore.isProfileLoggedIn" class="pr-2 bd-highlight">
      <ul class="nav">
        <li class="nav-item">
          <a
            class="nav-link nav-link-login"
            data-bs-toggle="dropdown"
            href="#"
            role="button"
            aria-expanded="false"
          >
            {{ authStore.profile.firstname }}
            <font-awesome-icon class="ml-10" icon="user" />
          </a>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item nav-link-login" href="/profile/edit"
                >{{ authStore.profile.firstname }} - {{ authStore.profile.profileid }}</a
              >
            </li>
            <li>
              <a class="dropdown-item nav-link-login" href="/profile/orders">Orders</a>
            </li>
            <li v-if="authStore.hasAssignedItems">
              <a class="dropdown-item nav-link-login" href="/profile/assigned-items"
                >Assigned Items</a
              >
            </li>
            <li v-if="authStore.hasOrderGuide">
              <a class="dropdown-item nav-link-login" href="/profile/order-guide"
                >Order Guide</a
              >
            </li>
            <li><hr class="dropdown-divider" /></li>
            <li>
              <a
                class="dropdown-item hand nav-link-login"
                @click="authStore.profileLogout"
                >Logout</a
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div v-else>
      <a class="nav-link nav nav-link-login" href="/login"
        ><font-awesome-icon icon="user" /><span class="ml-10">Login</span></a
      >
    </div>

    <!--- cart -->
    <a class="nav-link pr-2 nav nav-link-cart" href="/shopping-cart">
      <font-awesome-icon icon="cart-shopping" />
      <span class="ml-10">{{ authStore.totalItemsInCart }}</span>
    </a>
  </div>

  <SearchBar class="d-block d-sm-block d-md-none mt-2 mb-2"></SearchBar>

  <!--- Toast ---------------------------------------------------------------------------------->
  <div class="toast-container position-fixed top-0 end-0 p-3">
    <div
      id="toastCart"
      class="toast"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="toast-header">
        <img id="toast_cart_image" src="" class="rounded me-2 thumb-image" alt="" />
        <div class="me-auto">
          <strong id="toast_cart_manufacturer"></strong>
          <div id="toast_cart_title"></div>
        </div>

        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          @click="authStore.closeToastCart"
        ></button>
      </div>
      <div class="toast-body">
        <button class="btn btn-sm btn-success" @click="viewCart">view cart</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Define the glow effect */
.cart-count {
  position: relative;
  top: -10px;
  border-radius: 15px;
  padding: 4px;
  color: #666;
}
.ml-10 {
  margin-left: 10px;
}
</style>
