<script>
export default {};
</script>

<template>
  <div class="ribbon-container">
    <button
      type="button"
      class="ribbon"
      data-testid="freeShippingRibbon"
      aria-expanded="false"
    >
      <span class="ribbon-text"
        >Free Shipping <i class="ribbon__icon icon-question"></i
      ></span>
    </button>
  </div>
</template>

<style scoped>
.ribbon-container {
  margin-left: -25px;
  font-size: 0;
  margin-bottom: 0px;
}
.ribbon {
  margin: 0 5px 5px 0;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  line-height: 25px;
  padding: 0 10px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  transform: skewX(-15deg) skewY(0);
  background: #cf3700;
  border: 0;
}
.ribbon-text {
  display: block;
  transform: skewX(15deg) skewY(0);
}
</style>
