<script>
import CartButton from '../components/CartButton.vue';
import FreeShipping from '../components/FreeShipping.vue';
import ImageViewer from '../components/ImageViewer.vue';
import ModalPopupAsk from './ModalPopupAsk';
import AddonComponent from '../components/AddonComponent.vue';

export default {
  name: 'ItemComponent',
  components: {
    AddonComponent,
    CartButton,
    FreeShipping,
    ImageViewer,
    ModalPopupAsk
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    let tabsData = [];
    if (this.item.specs) {
      tabsData.push({ label: 'Specifications', content: this.item.specs });
    }
    if (this.item.warranty) {
      let warranty_html = this.item.warranty;
      if (this.item.warranty_pdf_url) {
        warranty_html += '<a href="' + this.item.warranty_pdf_url + '">PDF</a>';
      }
      tabsData.push({ label: 'Warranty', content: warranty_html });
    }
    return {
      masterItemIds: [],
      tabsData
    };
  },
  created() {
    this.masterItemIds = [this.item.id];
  },
  methods: {
    getShippingInfo(item) {
      let qty =
        item.stock_qty_warehouse_1 +
        item.stock_qty_warehouse_2 +
        item.stock_qty_warehouse_3 +
        item.stock_qty_warehouse_4;

      if (this.clientName == 'atlas' || this.clientName == 'midcity') {
        if (qty <= 0) {
          return ' <span class="not-in-stock">Not in Stock, back-ordered</span>';
        } else {
          if (this.clientName == 'atlas') {
            let avail =
              '<img src="https://ext-chef.s3.us-west-2.amazonaws.com/in_stock.gif" ';
            avail +=
              'title="in stock SB: ' +
              item.stock_qty_warehouse_1 +
              ' INDY: ' +
              item.stock_qty_warehouse_2 +
              ' GR: ' +
              item.stock_qty_warehouse_3 +
              ' FW: ' +
              item.stock_qty_warehouse_4 +
              '">';
            return avail;
          } else {
            return (
              '<img src="https://ext-chef.s3.us-west-2.amazonaws.com/in_stock.gif" title="in stock (' +
              item.stock_qty_warehouse_2 +
              ')">'
            );
          }
        }
      } else {
        return 'Ships in #availability#';
      }
    },

    getMonthlyLeaseAmount(price) {
      if (this.clientName == 'mychefstore') {
        let monthly = (price * 0.04).toFixed(2);
        return '<span class="label">Lease to Own:</span> $' + monthly + '/mo<br>';
      }
    },
    displaySpecs(specs) {
      return specs.replace(/(?:\r\n|\r|\n)/g, '<br />');
    },
    showModalAsk() {
      let myModal = new window.bootstrap.Modal(document.getElementById('modal-ask'));
      myModal.show();
    }

    // get qty select
  }
};
</script>

<template>
  <div class="item-container">
    <div class="row">
      <h4>{{ item.manufacturer }} <br />{{ item.title }}</h4>
      <div class="">Item # {{ item.model }}</div>
      <div class="mb-4">SKU # {{ item.facts_number }}</div>

      <!-- Image section -->
      <div class="col-md-7 col-sm-12">
        <ImageViewer :item="item"></ImageViewer>
      </div>

      <!-- Add to Cart / Options Section -->
      <div class="col-md-5 col-sm-12">
        <div class="panel panel-default">
          <div class="panel-body add-to-box">
            <FreeShipping v-if="item.free_freight || item.comp_freight"></FreeShipping>

            <div class="price mb-3 text-end">
              <div v-if="item.sell_price > 0 && item.show_pricing == 1" class="price">
                {{ $money(item.sell_price) }}
              </div>
              <div v-if="item.sell_price == 0 || item.show_pricing == 0" class="price">
                request quote
              </div>
            </div>

            <!--- add to cart -->
            <CartButton :item="item"></CartButton>

            <div class="py-4"><div class="border"></div></div>

            <!--- shipping info -->
            <span class="" v-html="getShippingInfo(item)"></span><br />
            <span v-html="getMonthlyLeaseAmount(item.sell_price)"></span>

            <!--- ask -->
            <div class="hand mt-3" @click="showModalAsk">
              <button class="btn btn-outline-primary btn-sm">Ask a Question</button>
            </div>
            <ModalPopupAsk
              :model="item.model"
              :manufacturer="item.manufacturer"
            ></ModalPopupAsk>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--- Suggested Add Ons -->
  <AddonComponent :master_item_ids="masterItemIds"></AddonComponent>

  <!--- energy_star -->
  <div v-if="item.energy_star">
    <img src="https://ext-chef.s3.us-west-2.amazonaws.com/energy_star.png" />
  </div>

  <!-- specs -->
  <div v-if="item.specs" class="item-container my-3">
    <h4>Specifications</h4>
    <div v-html="displaySpecs(item.specs)"></div>
  </div>

  <!-- warranty -->
  <div v-if="item.warranty" class="item-container my-3">
    <h4>Warranty</h4>
    {{ item.warranty }}

    <div class="pt-3 fw-light fs-6 fst-italic">
      HOMEOWNERS: The vendor is not responsible for any parts or labor costs related to
      component failure or damages arising from use in residential or non-commercial
      settings. Installing the product in such environments will invalidate your warranty.
    </div>
  </div>

  <!-- docs -->
  <div
    v-if="
      item.pdf_url ||
      item.warranty_pdf_url ||
      item.pdf_additional_info1_url ||
      item.pdf_additional_info2_url ||
      item.brochure_pdf_url
    "
    class="item-container my-3"
  >
    <h4>Resources and Downloads</h4>
    <div class="row">
      <div v-if="item.pdf_url" class="col">
        <a :href="item.pdf_url" target="_blank">
          <div class="px-4">
            <img class="pdf" src="https://ext-chef.s3.us-west-2.amazonaws.com/pdf.png" />
            <br />
            spec sheet
          </div></a
        >
      </div>
      <div v-if="item.pdf_additional_info1_url" class="col">
        <a :href="item.pdf_additional_info1_url" target="_blank">
          <div class="px-4">
            <img class="pdf" src="https://ext-chef.s3.us-west-2.amazonaws.com/pdf.png" />
            <br />
            info
          </div></a
        >
      </div>
      <div v-if="item.pdf_additional_info2_url" class="col">
        <a :href="item.pdf_additional_info2_url" target="_blank">
          <div class="px-4">
            <img class="pdf" src="https://ext-chef.s3.us-west-2.amazonaws.com/pdf.png" />
            <br />
            info
          </div></a
        >
      </div>
      <div v-if="item.warranty_pdf_url" class="col">
        <a :href="item.warranty_pdf_url" target="_blank">
          <div class="px-4">
            <img class="pdf" src="https://ext-chef.s3.us-west-2.amazonaws.com/pdf.png" />
            <br />
            warranty
          </div></a
        >
      </div>
      <div v-if="item.brochure_pdf_url" class="col">
        <a :href="item.brochure_pdf_url" target="_blank">
          <div class="px-4">
            <img class="pdf" src="https://ext-chef.s3.us-west-2.amazonaws.com/pdf.png" />
            <br />
            brochure
          </div></a
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
.pdf {
  width: 60px;
}
.add-to-box {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 15px;
}
.label {
  font-weight: regular;
  font-size: smaller;
  font-style: italic;
  color: #333;
  padding-right: 10px;
}
.addon-image {
  width: 100px;
}
.addon-title {
}
.flex-ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style-type: none;
  padding: 0;
  font-size: 13px;
}
.flex-ul li {
  flex: 1 1 22%; /* Adjust percentage as needed */
  box-sizing: border-box;
  margin: 0.5%;
}
.flex-ul li:not(:last-child) {
  border-right: 1px solid #ccc;
}
@media (max-width: 776px) {
  .flex-ul li {
    flex: 1 1 100%;
    margin: 0;
    padding: 20px 0;
  }
  .flex-ul li:not(:last-child) {
    border-right: none;
    border-bottom: 1px solid #ccc;
  }
}
</style>
