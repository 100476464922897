<script>
import { useAuthStore } from '@/stores/authStore';
import { ref } from 'vue';
import axios from 'axios';
import router from '@/router/index.js';

export default {
  data() {
    const authStore = useAuthStore();

    return {
      showPassword: false,
      email: '',
      email_reset: '',
      password: '',
      authStore,
      error_message: ''
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? 'Hide' : 'Show';
    }
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },

    async reset() {
      let data = { email: this.email_reset };
      await axios
        .post(this.apiUrl + 'profile/reset', data)
        .then((response) => {
          this.authStore.profile = response.data;
          this.showToast('Password Sent', response.content, '');
        })
        .catch((error) => {
          this.authStore.profile = [];
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    },

    async login() {
      this.error_message = '';
      let data = { email: this.email, password: this.password };
      this.authStore.sections = [];
      await axios
        .post(this.apiUrl + 'profile/login', data)
        .then((response) => {
          this.authStore.profile = response.data;

          // convert to array of ids
          this.authStore.profile.order_quide_item_ids =
            this.authStore.profile.order_quide_item_ids.split(',');

          this.authStore.profile.profile_assigned_to_item_ids =
            this.authStore.profile.profile_assigned_to_item_ids.split(',');

          if (this.authStore.hasOrderGuide) {
            router.push({ path: '/profile/order-guide' });
          } else {
            router.push({ path: '/' });
          }
        })
        .catch((error) => {
          this.authStore.profile = [];
          if (error.response && error.response.data && error.response.data.detail) {
            this.error_message = error.response.data.detail;
            //this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.error_message = error.message;
            //this.showToast('Error', error.message, 'error');
          }
        });
    }
  }
};
</script>

<template>
  <div class="card login_form mb-4">
    <h1 class="h3 mb-3 fw-normal">Please sign in</h1>

    <div class="mb-3">
      <label for="exampleInputEmail1" class="form-label">Email address</label>
      <input v-model="email" type="email" class="form-control" />
    </div>

    <div class="mb-3">
      <label for="exampleInputPassword1" class="form-label">Password</label>

      <div class="input-group">
        <input v-if="showPassword" v-model="password" type="text" class="form-control" />
        <input v-else v-model="password" type="password" class="form-control" />

        <div class="input-group-text">
          <span @click="toggleShow">
            <font-awesome-icon v-if="showPassword" icon="fa-eye-slash" />
            <font-awesome-icon v-else icon="fa-eye" />
          </span>
        </div>
      </div>
    </div>
    <div class="text-danger mb-3">{{ error_message }}</div>
    <button class="btn btn-primary" @click="login">Login</button>
    <hr />

    <a href="/profile/edit" class="create-account"
      ><button type="submit" class="btn btn-secondary">
        <i class="fas fa-user"></i> Create Account
      </button></a
    >
  </div>

  <hr />

  <div class="card login_form mt-4">
    <h1 class="h3 mb-3 fw-normal">Reset Password</h1>

    <div class="mb-3">
      <label for="exampleInputEmail1" class="form-label">Email address</label>
      <input v-model="email_reset" type="email" class="form-control" />
    </div>

    <button class="btn btn-secondary" @click="reset">Reset Password</button>
  </div>
</template>

<style scoped>
.login_form {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.create-account {
  text-align: center;
}
</style>
