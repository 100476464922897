<script>
import axios from 'axios';
import router from '@/router/index.js';
import { useAuthStore } from '@/stores/authStore';

export default {
  data() {
    const authStore = useAuthStore();
    return {
      authStore,
      profile: {
        profileid: 0,
        profile_company_id: this.$route.params.profile_company_id,
        email: '',
        firstname: '',
        lastname: '',
        title: '',
        phone: '',
        facts_number: '',
        email_opt_out: 0,
        inactive: 0,
        password: ''
      }
    };
  },
  mounted() {
    // API get profile
    if (this.$route.params.profileid > 0) {
      axios
        .get(this.apiUrl + 'admin/profile/' + this.$route.params.profileid)
        .then((response) => {
          this.profile = response.data;
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    }
  },
  methods: {
    cancelProfile() {
      this.$router.push({ path: '/admin/profile' });
    },
    saveProfile() {
      axios
        .post(this.apiUrl + 'admin/profile/save', this.profile)
        .then((response) => {
          this.showToast('Data saved', '', 'success');
          this.$router.push({ path: '/admin/profile' });
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    },
    modifyProfileCompany(profile_company_id) {
      router.push({ path: '/admin/profile/company/' + profile_company_id });
    },
    // auto login as profile - on ProfileList.vue and ProfileAddMod.vue
    async loginProfile(profileid) {
      const data = { profileid };
      await axios
        .post(this.apiUrl + 'admin/profile/login-as', data)
        .then((response) => {
          this.authStore.cartItems = [];
          this.authStore.profile = response.data;

          // convert to array of ids
          this.authStore.profile.order_quide_item_ids =
            this.authStore.profile.order_quide_item_ids.split(',');

          this.authStore.profile.profile_assigned_to_item_ids =
            this.authStore.profile.profile_assigned_to_item_ids.split(',');

          if (this.authStore.hasOrderGuide) {
            router.push({ path: '/profile/order-guide' });
          } else {
            router.push({ path: '/' });
          }
        })
        .catch((error) => {
          this.authStore.profile = [];
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    }
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center">
    <h2>Profile / Add Mod</h2>
    <div class="ms-auto">
      <span v-if="profile.profileid > 0">
        <button
          class="btn btn-light btn-sm mx-3"
          @click="modifyProfileCompany(profile.profile_company_id)"
        >
          View Company
        </button>
        <button
          class="btn btn-light btn-sm mx-3"
          @click="loginProfile(profile.profileid)"
        >
          Login As
          <font-awesome-icon icon="fa-sign-in" />
        </button>
      </span>
      <button class="btn btn-light btn-sm mx-3" @click="cancelProfile">Cancel</button>
      <button class="btn btn-primary btn-sm" @click="saveProfile">Save</button>
    </div>
  </div>

  <table class="">
    <tr>
      <td class="form-label">Inactive</td>
      <td>
        <input
          v-model="profile.inactive"
          class="form-check-input"
          type="checkbox"
          true-value="1"
          false-value="0"
        />
      </td>
    </tr>
    <tr>
      <td class="form-label">FACTS</td>
      <td>
        <input v-model="profile.profile_company_id" class="form-control" type="hidden" />
        <input v-model="profile.facts_number" class="form-control" type="text" />
      </td>
    </tr>
    <tr>
      <td class="form-label">title</td>
      <td><input v-model="profile.title" class="form-control" type="text" /></td>
    </tr>
    <tr>
      <td class="form-label">firstname</td>
      <td><input v-model="profile.firstname" class="form-control" type="text" /></td>
    </tr>
    <tr>
      <td class="form-label">lastname</td>
      <td><input v-model="profile.lastname" class="form-control" type="text" /></td>
    </tr>
    <tr>
      <td class="form-label">email</td>
      <td>
        <input v-model="profile.email" class="form-control" type="email" required />
      </td>
    </tr>
    <tr>
      <td class="form-label">phone</td>
      <td><input v-model="profile.phone" class="form-control" type="text" /></td>
    </tr>
    <tr>
      <td class="form-label">password</td>
      <td><input v-model="profile.password" class="form-control" type="text" /></td>
    </tr>
    <tr>
      <td class="form-label">email opt out</td>
      <td>
        <input
          v-model="profile.email_opt_out"
          class="form-check-input"
          type="checkbox"
          true-value="1"
          false-value="0"
        />
      </td>
    </tr>
  </table>
</template>
