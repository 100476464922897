<script>
import ModalPopupTerms from '@/components/ModalPopupTerms.vue';

export default {
  name: 'ShoppingCart',
  components: {
    ModalPopupTerms
    // AddonComponent
  },
  methods: {
    showModalTerms() {
      this.$refs.modalPopupTerms.show();
    }
  }
};
</script>

<template>
  <div class="text-center bg-primary bg-gradient mt-4">
    <ModalPopupTerms></ModalPopupTerms>
    Copyright © 2023 • Mid City Restaurant Supply • 500 Alakawa St, Honolulu, HI 96817<br />
    <span class="hand" @click="showModalTerms">Policies</span> •
    <a href="https://www.midcityre.com/privacy.cfm">Privacy Policy</a><br />
    <a href="http://www.extantware.com/" class="footer-extantware">ExtantWare.com</a>
  </div>
</template>
